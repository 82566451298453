import { render, staticRenderFns } from "./FooterNavigation.vue?vue&type=template&id=29979f2c&scoped=true&"
import script from "./FooterNavigation.vue?vue&type=script&lang=js&"
export * from "./FooterNavigation.vue?vue&type=script&lang=js&"
import style0 from "./FooterNavigation.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./FooterNavigation.vue?vue&type=style&index=1&id=29979f2c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29979f2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBottomNavigation,VBtn,VIcon,VTooltip})
