<template>
	<v-app-bar
		id="app-header"
		class="app-header"
		app
		flat
		hide-on-scroll
		:height="headerHeight"
	>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<router-link to="/">
					<v-img
						:height="imageHeight"
						:width="imageWidth"
						alt="Sydney Kids Neuropsychology"
						class="app-header__logo"
						src="/img/logo-blue.svg"
						v-on="on"
					/>
				</router-link>
			</template>
			<span>home</span>
		</v-tooltip>
		<site-navigation />
	</v-app-bar>
</template>

<script>
// import SiteNavigation from '@/components/global/SiteNavigation';
export default {
	name: 'TheHeader',
	components: {
		SiteNavigation: () => import('@/components/global/SiteNavigation')
	},
	computed: {
		headerHeight() {
			const breakpoint = this.$vuetify.breakpoint.name;
			if (breakpoint === 'xs' || breakpoint === 'sm') {
				return '78';
			} else {
				return '97';
			}
		},
		imageHeight() {
			const breakpoint = this.$vuetify.breakpoint.name;
			if (breakpoint === 'xs' || breakpoint === 'sm') {
				return '54';
			} else {
				return '73';
			}
		},
		imageWidth() {
			const breakpoint = this.$vuetify.breakpoint.name;
			if (breakpoint === 'xs' || breakpoint === 'sm') {
				return '210';
			} else {
				return '285';
			}
		}
	}
};
</script>

<style scoped lang="scss">
.app-header {
	background: rgba(255, 255, 255, 0.9) !important;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
	&__logo {
		z-index: zindex(level4);
	}
}
</style>
