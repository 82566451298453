import Vue from 'vue';
import Router from 'vue-router';
import './plugins/vuemeta';
import NProgress from 'accessible-nprogress';

Vue.use(Router);

NProgress.configure({
	showSpinner: false,
	speed: 500
});

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			icon: '$vuetify.icons.home',
			path: '/',
			name: 'Home',
			component: () =>
				import(/* webpackChunkName: "home" */ './views/Home.vue')
		},
		{
			icon: '$vuetify.icons.assessments',
			path: '/services',
			name: 'Our Services',
			component: () => import('./views/Services.vue')
		},
		{
			icon: '$vuetify.icons.autism',
			path: '/services/autism-spectrum-disorder',
			name: 'Autism Spectrum Disorder',
			child: true,
			component: () =>
				import('./views/assessments/AutismSpectrumDisorder.vue')
		},
		{
			icon: '$vuetify.icons.intellectual',
			path: '/services/intellectual-functioning',
			name: 'Intellectual Functioning',
			child: true,
			component: () =>
				import('./views/assessments/IntellectualFunctioning.vue')
		},
		{
			icon: '$vuetify.icons.school',
			path: '/services/school-readiness',
			name: 'School Readiness',
			child: true,
			component: () => import('./views/assessments/SchoolReadiness.vue')
		},
		{
			icon: '$vuetify.icons.academic',
			path: '/services/academic-assessment',
			name: 'Academic Assessment',
			child: true,
			component: () =>
				import('./views/assessments/AcademicAssessment.vue')
		},
		{
			icon: '$vuetify.icons.attention',
			path: '/services/attention-difficulties',
			name: 'Attention Difficulties',
			child: true,
			component: () =>
				import('./views/assessments/AttentionDifficulties.vue')
		},
		{
			icon: '$vuetify.icons.comprehensive',
			path: '/services/comprehensive-neuropsychology',
			name: 'Comprehensive Neuropsychology',
			child: true,
			component: () =>
				import('./views/assessments/ComprehensiveNeuropsychology.vue')
		},
		{
			icon: '$vuetify.icons.user',
			path: '/about',
			name: 'About Us',
			component: () => import('./views/About.vue')
		},
		{
			icon: '$vuetify.icons.faqs',
			path: '/faqs',
			name: 'Frequently Asked Questions',
			component: () => import('./views/Faqs.vue')
		},
		{
			icon: '$vuetify.icons.contact',
			path: '/contact',
			name: 'Contact Sydney Kids Psych',
			component: () => import('./views/Contact.vue')
		},
		{
			path: '/terms',
			name: 'Terms & Conditions',
			hidden: true,
			component: () => import('./views/Terms.vue')
		},
		{
			path: '/success',
			name: 'Success',
			hidden: true,
			component: () => import('./views/Success.vue')
		},
		{
			path: '*',
			name: 'Page Not Found',
			hidden: true,
			component: () => import('./views/404.vue')
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			document.getElementById('app').scrollIntoView();
		}
	}
});

router.beforeResolve((to, from, next) => {
	if (to.name) {
		NProgress.start();
	}
	next();
});

router.afterEach((to, from) => {
	NProgress.done();
});

export default router;
