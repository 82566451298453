<template>
	<v-app>
		<the-header />
		<v-content id="app-main" :class="{ loaded: hasLoaded }">
			<router-view />
		</v-content>
		<the-footer />
	</v-app>
</template>

<script>
import TheHeader from '@/components/global/TheHeader';
import TheFooter from '@/components/global/TheFooter';
export default {
	name: 'App',
	metaInfo: {
		title: 'Sydney Kids Neuropsychology',
		titleTemplate: '%s | SydKidsPsych'
	},
	components: {
		TheHeader,
		TheFooter
	},
	data() {
		return {
			hasLoaded: false
		};
	},
	mounted: function() {
		this.$nextTick(() => {
			this.hasLoaded = true;
			this.calcMainMargin();
		});
	},
	methods: {
		calcMainMargin() {
			const footerHeight = document.getElementById('app-footer')
				.offsetHeight;
			document.getElementById('app-main').style.marginBottom =
				footerHeight + 'px';
		}
	}
};
</script>

<style scoped lang="scss">
#app-main {
	background-color: #fafafa;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
	padding: 0 !important;
	position: relative;
	z-index: 1;
	&:after {
		background: linear-gradient(
			to right,
			var(--v-autism-base) 16.66%,
			var(--v-intellectual-base) 16.66%,
			var(--v-intellectual-base) 33.33%,
			var(--v-school-base) 33.33%,
			var(--v-school-base) 49.99%,
			var(--v-academic-base) 49.99%,
			var(--v-academic-base) 66.66%,
			var(--v-attention-base) 66.66%,
			var(--v-attention-base) 83.33%,
			var(--v-comprehensive-base) 83.33%,
			var(--v-comprehensive-base) 100%
		);
		bottom: 0;
		content: '';
		height: gutter-size(2);
		left: 0;
		position: absolute;
		right: 0;
	}
}
</style>
