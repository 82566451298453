import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import 'accessible-nprogress/dist/accessible-nprogress.min.css';

Vue.config.productionTip = false;

const { detect } = require('detect-browser');
const browser = detect();

if (browser.name === 'ie') {
	document.getElementById('ie-alert').className += ' is-visible';
	document.documentElement.className += ' overflow-hidden';
} else {
	new Vue({
		router,
		vuetify,
		mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
		render: h => h(App)
	}).$mount('#app');
}
