import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faAngleDown,
	faAngleUp,
	faBars,
	faBook,
	faBrain,
	faCheckCircle,
	faCheckSquare,
	faChild,
	faCode,
	faCogs,
	faCommentDots,
	faCopyright,
	faEnvelope,
	faExclamationTriangle,
	faGraduationCap,
	faHome,
	faLightbulb,
	faMapMarkerAlt,
	faPencilAlt,
	faPhone,
	faQuestion,
	faQuoteLeft,
	faSquare,
	faUser
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(
	faAngleDown,
	faAngleUp,
	faBars,
	faBook,
	faBrain,
	faCheckCircle,
	faCheckSquare,
	faChild,
	faCode,
	faCommentDots,
	faCopyright,
	faCogs,
	faEnvelope,
	faExclamationTriangle,
	faFacebookF,
	faGraduationCap,
	faHome,
	faLightbulb,
	faMapMarkerAlt,
	faPencilAlt,
	faPhone,
	faTwitter,
	faQuestion,
	faQuoteLeft,
	faSquare,
	faUser
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
