<template>
	<v-bottom-navigation
		dark
		shift
		height="100%"
		class="app-footer__nav"
	>
		<div
			v-for="route in pages"
			:key="route.path"
			class="app-footer__nav-item"
		>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						:to="route.path"
						exact
						class="app-footer__nav-btn"
						v-on="on"
					>
						<span class="app-footer__title">{{ route.name }}</span>
						<v-icon class="app-footer__nav-icon">
							{{ route.icon }}
						</v-icon>
					</v-btn>
				</template>
				<span>{{ route.name }}</span>
			</v-tooltip>
		</div>
	</v-bottom-navigation>
</template>

<script>
export default {
	name: 'FooterNavigation',
	data() {
		return {
			routes: this.$router.options.routes
		};
	},
	computed: {
		pages() {
			return this.routes.filter(route => !route.hidden);
		}
	}
};
</script>

<style lang="scss">
.app-footer {
	&__nav-btn {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
<style scoped lang="scss">
.app-footer {
	&__nav {
		background: none !important;
		box-shadow: none !important;
		flex-flow: row wrap;
		padding: gutter-size(5);
	}
	&__nav-item {
		height: 83px;
		width: 83px;
	}
	&__title {
		top: calc(100% - 36px) !important;
		white-space: pre-line;
		width: 120px;
	}
	&__nav-btn {
		min-height: 100%;
		min-width: 100% !important;
	}
	&__nav-icon {
		font-size: 24px;
		height: 24px;
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, "sm")}) {
		&__nav-item {
			height: 105px;
			width: 105px;
		}
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, "md")}) {
		&__nav-item {
			height: 89px;
			width: 89px;
		}
	}
	@media only screen and (min-width: #{map-get($grid-breakpoints, "lg")}) {
		&__nav-item {
			height: 100px;
			width: 100px;
		}
	}
}
</style>
